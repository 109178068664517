import React, {useState, useEffect,Suspense} from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { Constants } from '../../adapters/helpers/Constants';
import { getBreadcrumbsBySlug } from '../../adapters/helpers/getBreadcrumbsBySlug';
const BreadCrumbSEO = dynamic(() => import( '../SEOschema/BreadCrumbSEO'));
import Loader from '../../adapters/helpers/Loader';

const BreadCrumbs = ({breadcrumbs}) => {
  const [hasBreadcrumb, setHasBreadcrumb] = useState(false);
  const [breadCrumbValue, setBreadCrumbValue] = useState([]);
  
  useEffect(() => {
    let pathName = window?.location?.pathname;
    async function fetchData() {
      const breadCrumbsValue = await getBreadcrumbsBySlug(pathName)
        if(breadCrumbsValue && breadCrumbsValue.length > 1){
          setHasBreadcrumb(true)
          setBreadCrumbValue(breadCrumbsValue)
        }        
      }
      fetchData();
      let productSubNavEle = document.getElementById('ob-product-subnav-scroll');
      let headerEle = document.getElementsByClassName('zone-header');

      if(!productSubNavEle && headerEle) {
          headerEle[0].style.position = 'fixed';
      }
  }, []);
       
  return (
    <React.Fragment>
      <Suspense fallback={<Loader />}>
      {hasBreadcrumb && breadCrumbValue ? <React.Fragment><nav className="breadcrumb-main" aria-label={Constants.breadCrumpLabel} id="breadcrumb-main">
        <ul className="breadcrumb" itemScope aria-label="Breadcrumbs">
          {breadCrumbValue &&
            breadCrumbValue.map((val, key) => {
              return (
                <li key={key}>
                  {val.linkUrl ? (
                    <a aria-label={`${Constants.breadCrumpLabel}-${val.pageTitle}`} href={val.linkUrl}>{val.pageTitle}</a>
                  ) : (
                    <span className="activeMenu">{val.pageTitle}</span>
                  )}
                </li>
              );
            })}
        </ul>
      </nav>
      { Array.isArray(breadCrumbValue) && breadCrumbValue.length > 0 && <BreadCrumbSEO breadcrumb={breadCrumbValue} /> }
      </React.Fragment> : <React.Fragment><nav className="breadcrumb-main" aria-label={Constants.breadCrumpLabel} id="breadcrumb-main">
        <ul className="breadcrumb" itemScope aria-label="Breadcrumbs">
          <li>
            <span className="op-breadcrumb-loading">{Constants.Loading}</span>        
          </li>
        </ul>
    </nav>
    </React.Fragment>
  }
  </Suspense>
    </React.Fragment>
  );
};

BreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
};

export default BreadCrumbs;
